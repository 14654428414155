<template>
  <div>
    <div style="width: 100%" >
      <el-row style="text-algin: center; margin: auto; width: 80%">
        <el-col :span="6">
          <div class="map_title">
            <div style="margin: auto">校区：</div>
            <el-select
              style="margin: auto"
              v-model="xqindex"
              @change="xq_change"
            >
              <el-option
                v-for="item in xq"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="map_title">
            <div style="margin: auto">生活区：</div>
            <el-select
              style="margin: auto"
              v-model="shqindex"
              @change="shq_change"
            >
              <el-option
                v-for="item in shq_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="map_title">
            <div style="margin: auto">建筑名称：</div>
            <el-select
              style="margin: auto"
              v-model="building_name"
              @change="buildingChange"
            >
              <el-option
                v-for="item in building_list"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="map_title">
            <el-button
              type="primary"
              plain
              @click="selectBuildingInfo"
              v-loading.fullscreen.lock="fullscreenLoading"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="6">
          <el-button type="text" @click="openNoRoomList()"
            >无寝室人员</el-button>
          <el-button type="text" @click="show_fenpei_dialog = true" v-if="xy=='学生工作处'">分配给</el-button>
           <el-button type="text" @click="export_xlsx">数据导出</el-button>
           <!-- export_no_xlsx -->
           <el-button type="text" @click="start_select">数据查询</el-button>
           <el-button type="text" @click="export_no_xlsx">无寝室数据导出</el-button>
        </el-col>
        <el-col :span="6">
        <div class="map_title">
            <div style="margin: auto">高亮学院:</div>
            <el-select
              style="margin: auto"
              v-model="highlight_xy"
            >

              <el-option
                v-for="item in xy_list"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          </el-col>
      </el-row>

      <el-drawer
        title="未分配寝室人员"
        :visible.sync="table"
        direction="rtl"
        size="50%"
      >
        <el-table :data="AllNoRoomStudent.filter(data => !search || data.man_name.toLowerCase().includes(search.toLowerCase()))">
          <el-table-column width="70" property="nj" label="年级">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.nj === '2017' ? 'warning' : 'success'"
                disable-transitions
                >{{ scope.row.nj }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            width="150"
            property="man_number"
            label="学号"
          ></el-table-column>

          <el-table-column
            width="150"
            property="man_name"
            label="姓名"
          ></el-table-column>
              <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>

        </el-table-column>

          <el-table-column
            width="300"
            property="man_xy"
            label="学院"
          ></el-table-column>
          <el-table-column
            width="150"
            property="remark"
            label="备注"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="onInsertRoomByXh(scope.row)"
                type="text"
                size="small"
                >添加到</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-drawer>

      <el-dialog
        title="数据录入"
        style="width: 40%; margin: auto"
        :visible.sync="showInsertDialog"
      >
        <div style="display: grid; font-size: 22px; padding: 20px">
          <span> 学号:{{ click_student.man_number }} </span>
          <span> 姓名:{{ click_student.man_name }} </span>
          <span> 年级:{{ click_student.nj }} </span>
          <span> 学院:{{ click_student.man_xy }} </span>
          <span> 专业:{{ click_student.man_zy }} </span>
          <el-select
            style="margin-top: 1vh"
            v-model="room_select"
            filterable
            placeholder="请选择房间"
          >
            <div v-for="(item, index) in build_data" :key="index">
              <el-option
                v-if="i.type == '寝室'"
                v-for="i in item"
                disabled
                :key="i.id"
                :label="i.room_name"
                :value="i.id"
              >
              </el-option>
            </div>
          </el-select>
          <el-input
            type="number"
            v-model="position"
            placeholder="请输入床位(数字)"
          ></el-input>
          <el-button style="margin-top: 5vh" type="primary" @click="tryInsert"
            >提交</el-button
          >
        </div>
      </el-dialog>

      <el-row>
        <div
          v-for="(item, index) in build_data"
          style="display: grid"
          :key="index"
        >
          <div class="bmap_title">{{ index }}层</div>

          <div
            :style="`min-width:` + (item.length / 2) * 250 + `px;padding:20px`"
          >
            <div
              :style="
                `float:left;min-height:250px;margin:5px;padding:10px;width:` +
                (i.size * 150 + (i.size - 1) * 30) +
                `px !important;min-width:` +
                (i.size * 150 + (i.size - 1) * 30) +
                `px !important;background-color:#` +
               i.rgb +
                `;color:#` +
                i.color +
                `;`
              "
              @click="selectTheRoom(i)"
              v-if="i.x == 0"
              v-for="i in item"
              :key="i.id"
            ><div class="label2"  v-if="checkSelectList(i.room_id) == 1">选</div>
            <div style="line-height: 30px;width: 100%;height: 32px;" v-else></div>
             <div v-if="count(person[i.room_id])[1] != 0" style="color:red;font-size: 25px">{{ i.room_name }}</div>
              <div v-else style="font-size: 25px">{{ i.room_name }}</div>
              <div style="margin-top: 1vh">
                <div v-if="i.max != 0">
                  人数:{{ count(person[i.room_id])[0] }}/<span
                    style="color: red"
                    >{{ count(person[i.room_id])[1] }}</span
                  >/{{ i.max
                  }}<span
                    v-if="count(person[i.room_id])[2] == 1"
                    style="color: red"
                    >超</span
                  >
                </div>
                <div>阳台:{{ i.yt }}</div>
                <div>性别:{{ i.sex }}</div>
                <div>朝向:{{ i.cx }}</div>
                <div v-if="count(person[i.room_id])[3] == 0 && i.type =='寝室'">
                   <div >
                       空寝室
                  </div>
                </div>
                <div v-for="j in count(person[i.room_id])[3]" :key="j">
                  <div style="color:red" v-if="j.el == highlight_xy">
                        {{j.el}} {{j.count}}人
                  </div>
                  <div  v-else>
                       {{j.el}} {{j.count}}人
                  </div>
                </div>
                <div v-if="i.type == '寝室'">
                <div>学院:<br>{{ i.xy?i.xy:'未分配' }}</div>
                  <el-popover placement="right" width="700" trigger="hover">
                    <el-table :data="person[i.room_id]">
                      <el-table-column width="150" property="nj" label="年级">
                        <template slot-scope="scope">
                          <el-tag
                            :type="
                              scope.row.nj === '2017' ? 'warning' : 'success'
                            "
                            disable-transitions
                            >{{ scope.row.nj }}</el-tag
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="150"
                        property="man_number"
                        label="学号"
                      ></el-table-column>
                      <el-table-column
                        width="150"
                        property="man_name"
                        label="姓名"
                      ></el-table-column>

                      <el-table-column
                        width="300"
                        property="man_xy"
                        label="学院"
                      ></el-table-column>
                       <el-table-column
                        width="300"
                        property="man_zy"
                        label="专业"
                      ></el-table-column>

                      <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                          <el-button
                            @click="deleteStudentRoomByStudentId(scope.row,i.room_id)"
                            type="text"
                            size="small"
                            >删除</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button slot="reference">住宿情况</el-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
          <div
            :style="`min-width:` + (item.length / 2) * 250 + `px;padding:20px`"
          >
            <div
              :style="
                `float:left;min-height:300px;margin:5px;padding:10px;width:` +
                (i.size * 150 + (i.size - 1) * 30) +
                `px !important;min-width:` +
                (i.size * 150 + (i.size - 1) * 30) +
                `px !important;background-color:#` +
                i.rgb +
                `;color:#` +
                i.color +
                `;`
              "
              v-if="i.x == 1"
               @click="selectTheRoom(i)"
              v-for="i in item"
              :key="i.id"
            >
            <div class="label2"  v-if="checkSelectList(i.room_id) == 1">选</div>
            <div style="line-height: 30px;width: 100%;height: 32px;" v-else></div>
              <div v-if="count(person[i.room_id])[1] != 0" style="color:red;font-size: 25px">{{ i.room_name }}</div>
              <div v-else style="font-size: 25px">{{ i.room_name }}</div>
              <div style="margin-top: 1vh">
                <div v-if="i.max != 0">
                  人数:{{ count(person[i.room_id])[0] }}/<span
                    style="color: red"
                    >{{ count(person[i.room_id])[1] }}</span
                  >/{{ i.max
                  }}<span
                    v-if="count(person[i.room_id])[2] == 1"
                    style="color: red"
                    >超</span
                  >
                </div>
                <div>阳台:{{ i.yt }}</div>
                <div>性别:{{ i.sex }}</div>
                <div>朝向:{{ i.cx }}</div>
                 <div v-if="count(person[i.room_id])[3] == 0&& i.type =='寝室'">
                   <div >
                       空寝室
                  </div>
                </div>
                <div v-for="j in count(person[i.room_id])[3]" :key="j">
                  <div style="color:red" v-if="j.el == highlight_xy">
                        {{j.el}} {{j.count}}人
                  </div>
                  <div  v-else>
                       {{j.el}} {{j.count}}人
                  </div>
                </div>

                <div v-if="i.type == '寝室'">
                <div>学院:<br>{{ i.xy?i.xy:'未分配' }}</div>
                  <el-popover placement="right" width="700" trigger="hover">
                    <el-table :data="person[i.room_id]">
                      <el-table-column width="150" property="nj" label="年级">
                        <template slot-scope="scope">
                          <el-tag
                            :type="
                              scope.row.nj === '2017' ? 'warning' : 'success'
                            "
                            disable-transitions
                            >{{ scope.row.nj }}</el-tag
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="150"
                        property="man_number"
                        label="学号"
                      ></el-table-column>
                      <el-table-column
                        width="150"
                        property="man_name"
                        label="姓名"
                      ></el-table-column>

                      <el-table-column
                        width="300"
                        property="man_xy"
                        label="学院"
                      ></el-table-column>
                      <el-table-column
                        width="300"
                        property="man_zy"
                        label="专业"
                      ></el-table-column>

                      <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                          <el-button
                            @click="deleteStudentRoomByStudentId(scope.row,i.room_id)"
                            type="text"
                            size="small"
                            >删除</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button slot="reference">住宿情况</el-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-row>


        <el-dialog title="选择学院" :visible.sync="show_fenpei_dialog" >
          <el-form style="margin:auto;text-align:center;padding:20px;width:600px">

            <el-form-item label="学院" >
              <el-select filterable  v-model="select_xy_name" placeholder="选择将所选寝室分配给的学院">
                <el-option v-for="i in xy_list" :label="i" :value="i" :key="i"></el-option>

              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="show_fenpei_dialog = false">取 消</el-button>
            <el-button type="primary" @click="SetRoomsToXy()">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="条件查询" :visible.sync="show_select_input" >
          <el-form style="margin:auto;text-align:center;padding:20px;width:600px">

            <el-form-item label="学号" >
              <el-input placeholder="请输入学生学号" v-model="select_xh"></el-input>
            </el-form-item>
            <el-form-item label="姓名" >
              <el-input placeholder="请输入学生姓名" v-model="select_name"></el-input>
            </el-form-item>
            <el-form-item><el-button @click="select_get">开始查询</el-button></el-form-item>
          </el-form>
          <div>
              <el-table
              :data="select_list"
              style="width: 100%">
              <el-table-column
                prop="man_name"
                label="姓名"
                width="180">
              </el-table-column>
              <el-table-column
                prop="man_number"
                label="学号"
                width="180">
              </el-table-column>
              <el-table-column
                prop="xiaoqu_name"
                label="校区">
              </el-table-column>
               <el-table-column
                prop="shq_name"
                label="生活区名称">
              </el-table-column>
               <el-table-column
                prop="bulding_name"
                label="宿舍楼名称">
              </el-table-column>
               <el-table-column
                prop="room_name"
                label="房间号">
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="show_fenpei_dialog = false">取 消</el-button>
            <el-button type="primary" @click="end_select()">关  闭</el-button>
          </div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildingMap",
  data() {
    return {
      school: "",
      fa_token: "",
      xq: [],
      bi: [],
      xqindex: "",
      xq_name: "",
      shq: [],
      shq_list: [],
      shqindex: "",
      shq_name: "",
      building_name: "",
      building_list: [],
      build_data: [],
      build_person: [],
      fullscreenLoading: false,
      room_person: [],
      person: [],
      AllNoRoomStudent: [],
      qs_list: [], //寝室列表
      table: false,
      room_select: "",
      showInsertDialog: false,
      click_student: {},
      position: "",
      auth_type:'',
      xy:'',
      select_text:'已选',
      selected_room_list:[],
      show_fenpei_dialog:false,
      select_xy_name:'',
      xy_list:[],
      highlight_xy:'信息工程学院',
      search:'',
      select_xh:'',
      show_select_input:false,
      select_list:[],
      select_name:'',
    };
  },
  methods: {
    count(e) {
      if (e) {
        var flag = 0;
        var max_flag = 0;
        var xy_arr = [];
        //是否超出最大容纳值
        e.forEach((item) => {
          if (item.nj == "2017") {
            flag++;
          }
          xy_arr.push(item.man_xy)
          if (e.length > item.max) {
            //length不超过max
            max_flag = 1;
          }
        });
        //整理学院arr 计算各学院占比
        return [e.length, flag, max_flag,this.getCount(xy_arr)];
      } else {
        return [0, 0, 0,[]];
      }
    },
    start_select(){
      //开始弹窗数据查询字段
      this.show_select_input = true;
    },
    end_select(){
      this.show_select_input = false;
    },
    select_get(){
         const loading = this.$loading({
          lock: true,
          text: '数据查询中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$axios
          .post(
            "https://auth.api.timkj.cn/api/dorm/getInfoBySelect",
            {
              data:{
                xh:this.select_xh,
                name:this.select_name
              },
            },
            { headers: { token: this.fa_token } }
          )
          .then((res) => {
            var data = res.data;
            loading.close()
            if (data.code == 1) {
              // console.log(this.person[room_id])
                  this.select_list = data.data;

                this.$message.success(data.msg)
            } else {
              this.$message.info(data.msg);
            }
          })
          .catch((e) => {
            loading.close()
            this.$message.info("查询数据失败");
          });
    }
    ,
    checkSelectList(e){
        //room_id
        var room_id = e;
        if(this.selected_room_list.indexOf(e) == -1){
          //不存在
          return 0;
        }else{
          return 1;
        }

    },
    getCount(arr, rank,ranktype){
    var obj = {}, k, arr1 = [];
    for (var i = 0, len = arr.length; i < len; i++) {
        k = arr[i];
        if (obj[k])
            obj[k]++;
        else
            obj[k] = 1;
    }
    //保存结果{el-'元素'，count-出现次数}
    for (var o in obj) {
        arr1.push({el: o, count: obj[o]});
    }
    //排序（降序）
    arr1.sort(function (n1, n2) {
        return n2.count - n1.count
    });
    //如果ranktype为1，则为升序，反转数组
    if(ranktype===1){
        arr1=arr1.reverse();
    }
    var rank1 = rank || arr1.length;
    return arr1.slice(0,rank1);
},
    //设置分配房间
    SetRoomsToXy(){
        if(this.xy != '学生工作处'){
          this.show_fenpei_dialog = false;
          return false;
        }
        //进行获取所有已选择房间
        console.log(this.selected_room_list)
        if(!this.selected_room_list.length||this.selected_room_list.length == 0){
          //未选择
          this.show_fenpei_dialog = false;
          this.$message.info('您未选择任何房间,无法进行分配')
          return false;
        }
        if(this.select_xy_name==''){
          this.show_fenpei_dialog = false;
           this.$message.info('您未选择任何学院,无法分配')
           return false;
        }
        //开始进行数据请求

        const loading = this.$loading({
          lock: true,
          text: '房间分配中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });


           this.$axios
          .post(
            "https://auth.api.timkj.cn/api/dorm/SetRoomsToXy",
            {
              rooms: this.selected_room_list,
              xy_name: this.select_xy_name,
            },
            { headers: { token: this.fa_token } }
          )
          .then((res) => {
            var data = res.data;
            loading.close()
            if (data.code == 1) {
              // console.log(this.person[room_id])
                    console.log(data)

                this.$message.success(data.msg)
            } else {
              this.$message.info(data.msg);
            }
          })
          .catch((e) => {
            loading.close()
            this.$message.info("写入寝室数据失败");
          });
    }
      ,
    onInsertRoomByXh(e) {
      //检查当前学号 是否存在于 未分配列表

      this.click_student = e;
      this.showInsertDialog = true;
    },
    selectTheRoom(i){
      console.log(i)



      if(this.xy == '学生工作处'){
          if(this.selected_room_list.indexOf(i.room_id) == -1){
            this.room_select = i.room_id;
            this.selected_room_list.push(i.room_id)
          }else{
            this.selected_room_list.splice(this.selected_room_list.indexOf(i.room_id),1)
          }
      }else{
        //其他学院 只能进行房间单选
        console.log(i.xy)
        console.log(i.xy.split(','))
        if(this.selected_room_list.indexOf(i.room_id) == -1&&i.xy.split(',').indexOf(this.xy)!= -1){
            this.selected_room_list = [i.room_id]
            this.room_select = i.room_id;
            //选择后 显示当前学院未分配的列表
            this.openNoRoomList()
          }else{
            this.selected_room_list.splice(this.selected_room_list.indexOf(i.room_id),1)
          }
      }



    },
    deleteStudentRoomByStudentId(e,room_id){
      const loading = this.$loading({
          lock: true,
          text: '数据获取中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      this.$axios
          .post(
            "https://auth.api.timkj.cn/api/dorm/deleteStudentRoomByStudentId",
            {
              room_id: e.room_id,
              student_id: e.id,
            },
            { headers: { token: this.fa_token } }
          )
          .then((res) => {
            var data = res.data;
            loading.close()
            if (data.code == 1) {
              // console.log(this.person[room_id])
                    // console.log(e)
                    var arr = this.person[room_id];
                  arr.forEach((item,index)=>{
                      if(item.id == e.id){
                        // console.log(typeof arr)
                        delete arr[index]
                      }
                    })
                    var newarr = []
                    arr.forEach((item)=>{
                      if(item != 'undefined'){
                          newarr.push(item)
                      }
                    })
                    // console.log(newarr)
                    this.person[room_id] = newarr

                this.$message.success(data.msg)
            } else {
              this.$message.info(data.msg);
            }
          })
          .catch((e) => {
            loading.close()
            this.$message.info("写入寝室数据失败");
          });


    },
    tryInsert() {
      if (this.click_student && this.position != "" && this.room_select) {
        const loading = this.$loading({
          lock: true,
          text: '数据获取中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });



        this.$axios
          .post(
            "https://auth.api.timkj.cn/api/dorm/DoInsertStudentRoomById",
            {
              room_id: this.room_select,
              position: this.position,
              student_id: this.click_student.id,
              school:this.school
            },
            { headers: { token: this.fa_token } }
          )
          .then((res) => {
            var data = res.data;
            loading.close()
            if (data.code == 1) {
              this.$message.success(data.msg)

               var arr = this.person[this.room_select];

                    var newarr = []
                    arr.forEach((item)=>{
                      if(item != 'undefined'){
                          newarr.push(item)
                          console.log(item)
                      }
                    })
                    newarr.push(data.data[0])
                    // console.log(newarr)
                    this.person[this.room_select] = newarr

              var no_room = this.AllNoRoomStudent
              var new_no_room = [];
              no_room.forEach((item)=>{
                  if(item.id != this.click_student.id ){
                      new_no_room.push(item)
                  }
              })
              this.AllNoRoomStudent = new_no_room

              this.showInsertDialog = false;

            } else {
              this.$message.info(data.msg);
            }
          })
          .catch((e) => {
            loading.close();
            this.$message.info("写入寝室数据失败");
          });


      }
      // console.log(this.click_student);
      //TODO 进行异步请求 将学生添加到对应房间
      //得到返回结果
    },
    openNoRoomList() {
      this.GetallNoRoomStudent();
      this.table = true;
    },
    export_xlsx(){

      window.location.href = 'https://service-h8firslr-1259605885.bj.apigw.tencentcs.com/dhdEverydayReport/v1/export/existDormStudent?school_code='+this.school+'&xy='+this.xy
    },
    export_no_xlsx(){

      window.location.href = 'https://service-h8firslr-1259605885.bj.apigw.tencentcs.com/dhdEverydayReport/v1/export/notExistDormStudent?school_code='+this.school+'&xy='+this.xy
    },
    xq_change(e) {
      this.xq.forEach((item) => {
        if (item.value == e) {
          this.xq_name = item.label;
        }
      });
      // console.log(this.xq_name);
      this.shqindex = "";
      this.shq_name = "";
      var shq_list = [];
      this.shq.forEach((item) => {
        if (item.xq_id == e) {
          //找到校区 进行生活区数据初始化
          shq_list.push(item);
        }
      });

      this.shq_list = shq_list;
    },
    shq_change(e) {
      this.shq_list.forEach((item) => {
        if (item.value == e) {
          this.shq_name = item.label;
        }
      });
      var building_list = [];
      var a = []
      var b = []
      this.bi.forEach((item) => {
        if (item.shq_id == e) {
          if(a.indexOf(item.name)==-1){
            a.push(item.name)
            b.push(item.id)
          }
        }
      });
      a.forEach((item,index)=>{

        building_list.push({ label: item, id: b[index] });
      })

      this.building_list = building_list;
    },
    //点击进行查询
    selectBuildingInfo() {
       const loading = this.$loading({
          lock: true,
          text: '数据获取中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      this.$axios
        .post(
          "https://auth.api.timkj.cn/api/dorm/SelectBuildingMapByname",
          {
            school: this.school,
            xq_name: this.xq_name,
            shq_name: this.shq_name,
            name: this.building_name,
          },
          { headers: { token: this.fa_token } }
        )
        .then((res) => {
          var data = res.data;
          loading.close()
          if (data.code == 1) {
            var build_data = data.data.building;
            var build_person = data.data.person;

            this.build_data = build_data;

            this.build_person = build_person;
            var person = {};
            this.build_person.forEach((item) => {
              if (!person[item.room_id]) {
                person[item.room_id] = [];
              }
              person[item.room_id].push(item);
            });
            this.person = person;


            this.$message.success(data.msg);
          } else {
            this.$message.info(data.msg);
          }
        })
        .catch((e) => {
          loading.close()
          this.$message.info("获取建筑物布局数据失败,请稍后再试");
        })


    },

    GetallNoRoomStudent() {
      const loading = this.$loading({
          lock: true,
          text: '数据获取中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      this.$axios
        .post(
          "https://auth.api.timkj.cn/api/dorm/GetAllNoRoomStudent",
          {
            school: this.school,
          },
          { headers: { token: this.fa_token } }
        )
        .then((res) => {
          var data = res.data;
          loading.close()
          if (data.code == 1) {
            // console.log(data.data);
            this.AllNoRoomStudent = data.data;
          } else {
            this.$message.info(data.msg);
          }
        })
        .catch((e) => {
        loading.close()
          this.$message.info("获取未分配寝室人员失败");
        });


    },
    buildingChange(e) {
      this.building_name = e;
    },
  },
  created() {
    var fa_token = window.localStorage.getItem("fa_token");
    if (!fa_token) {
      window.location.href = "/";
      return false;
    }

    this.fa_token = fa_token;

    //存在token 携带token  获取生活区数据
    var fa_user_info = window.localStorage.getItem("fa_user_info");
    fa_user_info = JSON.parse(fa_user_info);
    var user_name = fa_user_info.username;
    var school = user_name.split("-");

    school = school[0];
    this.school = school;

// GetUserAuth

 this.$axios
      .post(
        "https://auth.api.timkj.cn/api/dorm/GetUserAuth",
        {school:this.school},
        { headers: { token: this.fa_token } }
      )
      .then((res) => {
        var data = res.data;
        if(data.code == 1){
            var auth = data.data.auth;
            this.auth_type = auth.auth_type
            this.xy = auth.xy
            var xy_list = data.data.xy_list
            this.xy_list = xy_list
        }else{
          this.$message.info("您暂未获取应用使用权限,请重新登录");
           this.$router.push({
                path:'/'
            })
        }



      })
      .catch((e) => {
        this.$message.info("获取权限数据失败");
      });


    //进行校区 生活区数据获取
    this.$axios
      .post(
        "https://auth.api.timkj.cn/api/dorm/GetDataByXqForApi",
        { s: this.school },
        { headers: { token: this.fa_token } }
      )
      .then((res) => {
        var data = res.data;
        // console.log(data);
        this.bi = data.build;
        this.xq = data.xq;
        this.shq = data.shq;
      })
      .catch((e) => {
        this.$message.info("获取生活区数据失败,请稍后再试");
      });
  },
};
</script>
<style scoped>
.map_title {
  display: inline-flex;
  text-align: center;
  align-items: center;
}
.bmap_title {
  width: 100%;
  margin-top: 2vh;
  text-align: left;
  font-size: 22px;
}
.bmap_top {
  text-align: center;
}

.bmap_bottom {
  text-align: center;
}
.sliding {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}

.label2{
font-size:13px;
line-height:32px
;background:orange;
right: -1px;
top: 6px;
z-Index:2;
    padding: 0 1em;
-webkit-transform-origin:left bottom;
-moz-transform-origin:left bottom;
transform-origin:left bottom;
-webkit-transform:translate(29.29%,-100%) rotate(45deg);
-moz-transform:translate(29.29%,-100%) rotate(45deg);
transform:translate(29.29%,-100%) rotate(45deg);
text-indent:0;
font-size: 13px;
    text-indent: 0;
    width: 6px;
    margin: auto;
    position: initial;
    margin-right: 1%;
    color: white;
    border-radius: 39px;
}

</style>
